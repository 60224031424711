 
.language-toggle {

  button {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
 
.language-toggle_modal_content {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-row-gap: 5px;
  grid-column-gap: 5px;
}