@import '~@amzn/awsui-design-tokens/polaris.scss';

footer {
  display: block;
  bottom: 0;
  font-size: 12px;
  padding: 0.5rem;
  margin: 0;
  min-height: 18px;
  font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  background: $color-background-home-header;
  color: $color-text-home-header-default;
}

footer a {
  cursor: pointer;
  color: $color-text-home-header-default !important;
  min-width: 50px;
  text-align: center;
  text-decoration: none;

  &:hover {
    color: $color-text-accent !important;
  }
}

.cookie-preferences {
  float: right;
  margin-right: 10px;
}
.role-spoofing {
  float: right;
  cursor: pointer;
}

#copyright {
  color: $color-text-home-header-secondary !important;
}
