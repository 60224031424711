@use '~@amzn/awsui-design-tokens/polaris.scss' as awsui;

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: awsui.$color-background-container-content;
  opacity: 0.8;
  z-index: 998;
}