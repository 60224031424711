.rating-summary {
    font-size: 11px;
  }

  .rating-item {
    display: block;
  }
  
  .rating-label {
    display: inline-block;
    width: 50px;
  }
  
  .rating-bar {
    display: inline-block;
    width: 130px;
    height: 17px;
    position: relative;
    top: 4px;
    border: 1px solid #ccc;
  }
  
  .rating-bar-fill {
    display: inline-block;
    height: 15px;
    padding: 1px;
    width: 0px;
    background-color: darkorange;
  }
  