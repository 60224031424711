.multi-select-container {
  div:first-of-type {
    width: 15em;
  }
  div[class^='_dropdown'] {
    width:210px;
    ul {
      overflow-x: hidden;
    }
  }
}
