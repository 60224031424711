.amazon-federate-logo {
  width: 25px;
  border-radius: 6px;
  border: 2px solid #fff;
}

.primary-account-details {
  margin-bottom: 5rem;
}

.account-details-title {
  margin-bottom: 2rem;
}

.user-provider-span {
  margin-right: 1rem;
}

.mfa-header {
  margin-bottom: 4rem;
}
