@import '~@amzn/awsui-design-tokens/polaris.scss';

$default-color: #D1D5DB;
$active-color: #539FE5;
$line-color: #7D8998;

.theme-toggle {

  div[class^="awsui_child"] {
    &:first-child {
      border-right: 1px solid $line-color;
      padding-right: $space-static-xs;
    }
    button {
      padding-right: 0 !important;
      padding-left: 0!important;
      color: $default-color !important;
    
      &[data-active="true"] {
        color: $active-color !important;
      }

      &:hover {
        border-color: transparent !important;
        background: inherit !important;
      }
    }
  }
  
}


