@import '~@amzn/awsui-design-tokens/polaris.scss';

$bg: #0F1B2A;
$active-color: #539FE5;
$dropdown-height: 260px;
$dropdown-min-width: 700px;

.topnav {
  position: relative;
  z-index: 1000;

  &_aside {
    position: absolute;
    right: $space-static-xxl;
    top: 50%;
    transform: translateY(-50%);
    height: inherit;
    display: flex;

    div[class^="awsui_button-dropdown"] {
      
      button {
        background-color: $bg !important;
        color: $active-color !important;
      }

      div[class^="awsui_dropdown"] {
        
        div [class^="awsui_dropdown-content"] {
          max-height: 300px !important;

          li {
            background-color: $bg !important;
            border: 2px solid transparent !important;
            border-radius: 0 !important;
            z-index: 0 !important;

            &:hover {
              color: $active-color !important;
            }

            &:first-child {
              padding-top: $space-static-xs !important;
              padding-bottom: $space-static-xs !important;
            } 
        
            &:not(:first-child, :last-child) {
              font-weight: 700 !important;
              font-size: 16px !important;
              line-height: 22px !important;
              padding: $space-static-xxs 0 !important;
            }

            &:nth-child(2) {
              border-top: 2px solid #7D8998 !important;
              padding-top: $space-static-xs !important
            }

            &:nth-last-child(2) {
              border-bottom: 4px solid #7d8998 !important;
              padding-bottom: $space-static-xs !important
            }

            a {
              padding-top: 0 !important;
            }

            &:last-child {
              display: flex;
              span {
                background-color: $color-background-button-primary-default;
                margin: $space-static-l 0 !important;
                margin-left:  $space-static-xl !important;
                border-radius: $border-radius-button;
                color: black !important;
                font-weight: 800;
              }
            }
          }
          
        }
      }
    }
  }

  header[class^="awsui_top-navigation"] {
    justify-content: flex-start !important;
    display: flex;
    background-color: $bg !important;
    
    div[class^="awsui_identity"] a {
      width: 100px;
      img {
        max-width: 42px;
        margin-top: 15px !important;
      }
  
      span {
        font-weight: 400 !important;
      }
    }

    div[class^="awsui_utilities"] {
      // padding: 0 $space-static-l !important;
      // gap: $space-static-m;
      div[class^="awsui_utility-wrapper"] {
        &:after {
          display: none !important;
        }
      }
    }

   

    div[class^="awsui_dropdown"] {
      transform: translateY(0px) !important;

      div[class^="awsui_dropdown-content-wrapper"] {
        background-color: $bg !important;
        // height: $dropdown-height;
        min-width: $dropdown-min-width;
    
        // &::after {
        //   display: none;
        // }
    
        div[class^="awsui_ie11-wrapper"] {
          height: inherit;
    
          div[class^="awsui_dropdown-content"] {
            height: inherit;
            max-height: unset !important;
            width: 100% !important;
    
            ul {
              display: grid;
              grid-template-columns: repeat(2, minmax(270px, 1fr));
              grid-template-rows: repeat(3, 1fr);
              grid-auto-flow: column;
              grid-column-gap: $space-static-xxl;
              grid-row-gap: $space-static-xxl;
              height: inherit;
              padding: $space-static-xl !important;
    
              li {
                border: none  !important;
    
                &:last-child:nth-child(3) {
                  grid-row-start: 1;
                }
    
                &::after {
                  content: attr(data-description);
                  // position: absolute;
                  // bottom: 0;
                  color: #9BA7B6;
                  font-weight: 400;
                  font-size: 14px;
                  // top: 30px;
                }
    
                [role="menuitem"] {
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 22px;
                  padding-left: 0 !important;
                }
              }
            }
            
          }
        }
      
      }
    }
  }
}


