@import '~@amzn/awsui-design-tokens/polaris.scss';

#b {
  // accommodate the space for the fixed header
  padding-top: 38px;
}

#h {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  // corresponds to the z-index in Mezzanine
  z-index: 1000;
  margin: 0;
  padding: 0 30px;
  background: $color-background-home-header;
}

// temporary fix for this issue: https://issues.amazon.com/issues/AWSUI-10091
// it is needed for 2.1 components and should be removed after we migrate Modal and TablePreferences
.awsui-modal-open .awsui {
  z-index: 2000;
}

// needs to be more specific than `.awsui ul` style
.awsui ul.menu-list {
  display:flex;
  margin:0;
  padding:0;
  list-style:none;

  & > li {
    padding:0 4px;
    margin: 13px 0 9px;

    a, div {
      float:left;
      color: $color-foreground-control-default;
      line-height:16px;
    }

    a, a:hover {
      cursor:pointer;
      text-decoration:none;
    }

    &.title {
      font-weight: bold;
    }

    &.selected > a {
      font-weight:bold;
      color: $color-text-accent;
    }

    &.separated {
      border-left: 1px solid $color-border-control-default;
      padding-left: 9px;
      margin-left: 9px;
    }

    &.ml-auto {
      margin-left:auto;
    }
  }
}

.awsui-polaris-dark-mode {
  .awsui-app-layout {
    h2,
    h3,
    .awsui-util-container-header {
      color: $color-text-heading-default;
    }
  }
}
