// shared
$awsOrange: #ec7211;
$awsOrangeHeavy: #eb5f07;
$awsOrangeHeavyAlt: #dd6b10;
$awsBlue: #242f3e;
$awsBlueLight: #425672;
$awsBlueLightAlt: #3c4e67;
$awsBlueLightAlt2: #5c789f;
$awsNavbarColor: $awsBlue;
$awsError: #d13212;
$awsSuccess: #1d8102;
$awsPrimaryColor: $awsOrange;
$awsSecondaryColor: $awsBlue;
$primaryWhite: #ffffff;
$polarisLightGrey: #eaeded;
